.device-calendar .button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
}
.device-calendar .button:hover {
    color: red;
}

.blink {
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
    0% {
      background-color: blue;
    }
  }