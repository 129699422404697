@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

a {
  color:inherit;
  text-decoration: none;
 }
.container {
  width: 281mm;
  height: 194mm;
  font-size: 12px;
  padding: 8mm;
}

p {
  margin: 0;
}

h1 {
  margin: 0;
}

.line {
  align-items: center;
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
}

.header {
  /* height: 39mm; */
  display: flex;
  flex-direction: column;
}

.value {
  flex-grow: 1;
  font-weight: bold;
}

.value-address {
  flex-grow: 1;
  font-weight: bold;
  text-align: left;
  text-indent: 1%;
}

.value-lastname {
  flex-grow: 1;
  font-weight: bold;
  text-align: left;
  text-indent: 8%;
}

.value-phone {
  flex-grow: 1;
  font-weight: bold;
  text-align: left;
}

.value-email {
  flex-grow: 1;
  font-weight: bold;
  text-align: left;
  text-indent: 8%;
}

.label {
  padding-left: 2px;
  padding-right: 2px;
}

.label-address {
  padding-left: 2px;
  padding-right: 2px;
  vertical-align: middle;
}

.header .line {
  border: 2px black solid;
  border-top: 0;
  min-width: 25%;
  text-align: center;
}

.header .line:nth-child(1) {
  border: 3px black solid;
  justify-content: space-between;
}

.header .line:nth-child(1) *:nth-child(odd) {
  width: 30%;
}

.header .line:nth-child(2) *:nth-of-type(4) {
  flex-grow: 0.5;
}

.header .line:nth-child(3) *:nth-child(6) {
  flex-grow: 0.5;
}

.header .line:nth-child(4) {
  align-items: flex-start;
}

.block1 {
  width: 100%;
  height: 79mm;
  display: flex;
  flex-direction: row;
  padding-top: 4mm;
}

.block1 .code-container {
  display: flex;
  font-weight: bold;
  flex-direction: column;
  flex-wrap: wrap;
  height: 92%;
}

.block2 {
  width: 48.8%;
  height: 32mm;
  display: flex;
  flex-direction: column;
  padding-top: 4mm;
}

.block3 {
  width: 48.8%;
  height: 26mm;
  display: flex;
  flex-direction: column;
}

.cj {
  border-collapse: collapse;
  border-color: black;
  border-spacing: 0;
}

.cl {
  border: 1px solid;
  border-collapse: collapse;
  border-color: black;
  border-spacing: 0;
}

.cl-c3ow {
  border: 0;
  border-collapse: collapse;
  border-color: black;
  border-spacing: 0;
  text-align: left;
  vertical-align: top;
  width: 35mm;
  height: 7mm;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
}

.cl-ydyv {
  border-left: 1px solid;
}

.cl-ugbn {
  text-align: left;
  vertical-align: top;
  width: 35mm;
  height: 59mm;
  border-top: 1px solid;
}

.cl2 {
  border: 1px solid;
  border-collapse: collapse;
  border-color: black;
  border-spacing: 0;
  border-bottom: white;
}

.cl-phtq1 {
  border: 1px solid;
  border-collapse: collapse;
  border-color: black;
  border-spacing: 0;
  text-align: left;
  width: 24mm;
  height: 12mm;
  border-bottom: 0;
  border-right: 0;
  font-weight: bold;
  text-indent: 5%;
}

.cl-phtq2 {
  border: 1px solid;
  border-collapse: collapse;
  border-color: black;
  border-spacing: 0;
  text-align: center;
  width: 12mm;
  height: 12mm;
  border-bottom: 0;
  border-right: 0;
  font-weight: bold;
}

.cl-phtq3 {
  border: 1px solid;
  border-collapse: collapse;
  border-color: black;
  border-spacing: 0;
  text-align: left;
  width: 25mm;
  height: 12mm;
  border-bottom: 0;
  border-right: 0;
  text-indent: 15%;
  font-weight: bold;
}

.cl-phtq4 {
  border-top: 1px solid;
  width: 52mm;
  font-size: 10px;
  vertical-align: top;
  overflow: hidden;
}

.cl-phtq4 > div {
  height: 12mm;
}

.pclass {
  text-align: center;
  text-indent: 10%;
}

.cl-phti {
  border: 1px solid;
  border-collapse: collapse;
  border-color: black;
  border-spacing: 0;
  height: 1.4rem;
  border-bottom: 0;
  text-align: center;
  border-right: 0;
}

.cl-phti2 {
  border: 1px solid;
  border-collapse: collapse;
  border-color: black;
  border-spacing: 0;
  text-align: left;
  height: 1.4rem;
  border-left: 0;
}

.cl-phti3 {
  border: 1px solid;
  border-collapse: collapse;
  border-color: black;
  border-spacing: 0;
  height: 1.4rem;
  border-bottom: 0;
  border-top: 0;
  text-align: center;
  border-right: 0;
}

.cl-phti4 {
  border: 1px solid;
  border-collapse: collapse;
  border-color: black;
  border-spacing: 0;
  text-align: left;
  height: 1.4rem;
  border-bottom: 0;
  border-left: 0;
}

.cl-phtl1 {
  border: 1px solid;
  border-collapse: collapse;
  border-color: black;
  border-spacing: 0;
  text-align: left;
  vertical-align: top;
  height: 15mm;
  width: 58mm;
  border-left: 0;
}

.cl-phtl2 {
  border: 1px solid;
  border-collapse: collapse;
  border-color: black;
  border-spacing: 0;
  text-align: left;
  vertical-align: top;
  height: 15mm;
  width: 77mm;
  border-right: 0;
}

.cl-phtl-bis {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  vertical-align: top;
  height: 15mm;
  width: 12mm;
  border-top: 1px solid;
  border-bottom: white;
}

.cl-phtj {
  text-align: left;
  width: 58mm;
  height: 6mm;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cl-phto {
  text-align: right;
  width: 58mm;
}

.cl-phtx {
  border: 1px solid;
  border-collapse: collapse;
  border-color: black;
  border-spacing: 0;
  text-align: left;
  vertical-align: top;
  width: 52mm;
  height: 1.4rem;
  border-left: 0;
  border-right: 0;
}

.cl-phtz {
  text-align: left;
  vertical-align: top;
  height: 4.8rem;
}

.cm-phti {
  text-align: left;
  height: 1.4rem;
}

.divflex {
  display: flex;

  /* display: inline-block; */
}

.imagetable {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid;
  margin-left: 6mm;
  width: 138mm;
}

.imgt-yugn {
  vertical-align: top;
  text-align: center;
  height: 15mm;
  width: 142mm;
}

.imgt-disn {
  border: 1px solid;
  height: 86mm;
  width: 142mm;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

.imgt-vsnb1 {
  border: 1px solid;
  height: 47mm;
  width: 35mm;
  border-left: 0;
  border-bottom: 0;
}

.imgt-vsnb2 {
  border: 1px solid;
  height: 47mm;
  width: 28mm;
  border-left: 0;
  border-bottom: 0;
}

.imgt-vsnb3 {
  border: 1px solid;
  height: 47mm;
  width: 23mm;
  border-left: 0;
  border-bottom: 0;
}

.imgt-vsnb4 {
  border: 1px solid;
  height: 47mm;
  width: 55mm;
  border-left: 0;
  border-bottom: 0;
  border-right: 0;
}

.visa {
  font-family: "Architects Daughter", cursive;
  font-size: 40px;
  font-weight: bold;
  text-align: right;
  padding-right: 30px;
  margin-top: -13px;
}

.visa2table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid;
  width: 138mm;
  margin-left: 6mm;
  border-top: 0;
  border-bottom: 0;
}

.imgt-fnvb1 {
  border: 1px solid;
  width: 64mm;
  height: 15mm;
  vertical-align: top;
  text-align: left;
  border-top: 0;
  border-left: 0;
}

.imgt-fnvb2 {
  border: 1px solid;
  width: 55mm;
  height: 15mm;
  vertical-align: top;
  text-align: left;
  border-top: 0;
  border-right: 0;
}

.imgt-fnvb-bis {
  width: 23mm;
  height: 15mm;
  border-top: 0;
  border-bottom: 0;
  border: 0;
}

.device-calendar .button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
}
.device-calendar .button:hover {
    color: red;
}

.blink {
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
  }
  
  @-webkit-keyframes blinker {
    0% {
      background-color: blue;
    }
  }
  
  @keyframes blinker {
    0% {
      background-color: blue;
    }
  }
